import request from "./utils/request";
import requestNonAuthenticated from "./utils/requestNonAuthenticated";

export const gusto = {
    postGustoCode: (code, company) => {
        return requestNonAuthenticated('GET', `gusto/callback?code=${code}&company=${company}`);
    },
    syncedEmployees: (company, update) => {
        return request('GET', `gusto/sync-gusto-employees?company=${company}&update=${update}`);
    },
    putGustoConnection: (gusto_connected, company) => {
        return request('PUT', 'gusto/connection-update', { gusto_connected, company });
    }
}