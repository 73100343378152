import {useState, useEffect} from "react";
import {useRecoilState} from "recoil";
import {ArrowRightOutlined} from "@ant-design/icons";

// Components
import PageLoading from "sharedComponents/Loading/PageLoading";

// Store
import {generalCurrentSettings} from "store";

// Hooks
import useQuery from "Hooks/useQuery";

// Models
import {gusto} from "models/gusto";

// Utils
import {getCookie} from "utils/getCookie";

// Styles
import './style/redirected_gusto.scss';

const RedirectedGusto = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [_, setGeneralSettings] = useRecoilState(generalCurrentSettings);
    const query = useQuery()

    const gustoCode = query.get('code')

    const title = gustoCode ? 'Success!' : 'Cancelled!';
    const description = gustoCode
        ? 'Thank you for connecting with Gusto'
        : 'You didn’t connect with Gusto';
    const buttonText = gustoCode ? 'Go to Sync Data' : 'Go to Integrations';

    const subdomainCookie = getCookie('key');

    const handleNavigation = () => {
        const currentHost = window.location.hostname;
        window.location.href = `${window.location.protocol}//${subdomainCookie}.${currentHost}/settings?tab=integrations&activePanel=Gusto`;
    }

    const sendGustoCode = async () => {
        try {
            setIsLoading(true)
            const response =  await gusto.postGustoCode(gustoCode, subdomainCookie)

            if (!response?.status) {
                throw new Error('Something went wrong');
            }

            setGeneralSettings((prevState) => ({
                ...prevState,
                gusto_connected: 1,
                bamboo_hr_connected: 0
            }));

        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (gustoCode) {
            sendGustoCode();
        } else {
            setIsLoading(false);
        }
    }, [gustoCode]);


    if (isLoading) {
        return <PageLoading />
    }

    return(
        <div className='gusto_page_container'>
            <div className='modal_container modal'>
                <div>
                    <div className={`modal-title ${gustoCode ? 'success' : 'cancelled'}`}>
                        {title}
                    </div>
                    <div className="modal-description">
                        {description}
                    </div>
                    <button
                        className="gusto-redirect-button"
                        onClick={handleNavigation}
                    >
                        {buttonText} <ArrowRightOutlined className="right_arrow"/>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default RedirectedGusto