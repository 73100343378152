import axios from 'axios';
import useNotification from "Hooks/useNotification";

export default function requestNonAuthenticated(method, url, data, params, pspApiUrl = true) {
    const errorNote = useNotification;
    const apiUrl = process.env.valueOf().REACT_APP_API_URL;

    return axios({
        method: method,
        url: pspApiUrl ? apiUrl + url : url,
        headers: {},  // No authorization header for non-authenticated requests
        data: data,
        params,
    }).then((response) => {
        return response;
    }).catch((error) => {
        if ((error?.response?.status === 300) || (error?.response?.status === 404) || (error?.response?.status === 403)) {
            console.log(error.response);
            throw error;
        }

        // Handle non-authenticated errors (e.g., specific to 3rd party tools)
        let errorMessage;

        if (error.response && error.response.data) {
            if (typeof error.response?.data?.error_message !== 'object') {
                errorMessage = error.response?.data?.error_message ?? error.response?.data?.message;
            } else {
                errorMessage = Object
                    .keys(error.response.data.error_message)
                    .map(key => error.response.data.error_message[key]).join(' ');
            }
        }

        if (errorMessage === 'Server Error') {
            errorMessage = 'Something went wrong';
        }

        errorNote(
            "error",
            "Warning!",
            errorMessage
        );
        console.log((error.response && error?.response?.data?.errors?.name) || error?.response?.data?.message, "Error");
    });
}
