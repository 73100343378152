import React, {Suspense, useEffect, lazy, useState, useLayoutEffect} from 'react';
import Joyride, {ACTIONS, EVENTS} from 'react-joyride';
import {Helmet} from "react-helmet";
import {useRecoilState, useRecoilValue} from "recoil";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {Row, Col} from 'antd';

// Components
import PageLoading from "sharedComponents/Loading/PageLoading";
import Login from '../Login/Login';
import RedirectedGusto from "./components/components/RedirectedGusto";

// HOC
import NotFoundUI from "HOC/withPermissionErrorBoundary/NotFoundUI";

// Store
import {
    selectCompanyData,
    selectGeneralCurrentSettings,
    selectIsUserFirstLoggedIn,
    selectMe,
    selectRole,
    selectSidebarCollapsed
} from "store/selectors";
import {isFreeTrial, isQuickTourActive, isYourCompanyBlocked} from "store";

// Hooks
import {useTimezone, usePersist, useTaskManager, dispatch} from 'Hooks';
import {useAuth, useDeviceType} from "context";

// Styles
import './style/dashboard.scss';

// Utils
import {getPermission} from "../../../permissions/permissions.config";
import getQuickTourSteps from "utils/getQuickTourSteps";
import {getGamificationRoutes} from "utils/memoize";

// Models
import {auth} from "models/auth";

// Assets
import eleapLogoDark from '../../../assets/images/eleap-logo-dark.svg';

// Lazy Components
const Notifications = React.lazy(() => import("sharedComponents/Notifications"));
const SideMenu = lazy(() => import('sharedComponents/SideMenu/SideMenu'));
const UserActions = lazy(() => import("sharedComponents/UserActions/UserActions"));
const Footer = lazy(() => import("sharedComponents/Footer/Footer.jsx"));
const ExportIndicator = lazy(() => import("sharedComponents/ExportIndicator"));
const SelectionBar = lazy(() => import("sharedComponents/SelectionBar"));
const PulsePointPopUp = lazy(() => import("sharedComponents/PulsePointPopUp"));
const CompanySettings = lazy(() => import('pages/CompanySettings/CompanySettings.js'));
const FreeTrial =  lazy(() => import('./FreeTrial'));
const BlockedAccount = lazy(() => import("./BlockedAccount"));


const Dashboard = () => {
    const {authTokens} = useAuth();
    const {isMobile} = useDeviceType();

    const isRedirectFromGusto = window.location.pathname.includes('/gusto/callback')

    useTimezone();
    useTaskManager([
        {
            cronDate: '03:00:00',
            fn: () => {
                dispatch({type: 'OPEN_POP_UP'});
            }
        }]);
    const isSidebarCollapsed = useRecoilValue(selectSidebarCollapsed);
    const [isTourActive, setIsTourActive] = useRecoilState(isQuickTourActive);
    const userRole = useRecoilValue(selectRole);
    const user = useRecoilValue(selectMe);
    const companyWholeData = useRecoilValue(selectCompanyData);
    const isFirstLogin = useRecoilValue(selectIsUserFirstLoggedIn);
    const generalSettings = useRecoilValue(selectGeneralCurrentSettings);
    usePersist('user', user);
    const routesWithGamification = getGamificationRoutes(generalSettings, user);
    const [isFreeTrialExpired, SetIsFreeTrialExpired] = useRecoilState(isFreeTrial);
    const [isAccountBlocked, setIsAccountBlocked] = useRecoilState(isYourCompanyBlocked);
    const [freeTrialAdmin, SetFreeTrialAdmin] = useState(null);

    const handleTourActions = (event) => {
        if (event.action === ACTIONS.CLOSE || event.type === EVENTS.TOUR_END) {
            setIsTourActive(false);
        }
    };

    const checkFreeTrial = async () => {
        try {
            const response = await auth.checkFreeTrial();

            if (!response?.data?.success) {
                throw new Error(response?.message);
            }

            SetIsFreeTrialExpired(response?.data?.free_trial_expires);
            SetFreeTrialAdmin(response?.data?.user);
        } catch (e) {
            console.log(e);
        }
    };

    const checkUnpaidInvoices = async () => {
        try {
            const response = await auth.checkUnpaidInvoices();

            if (!response?.data?.success) {
                throw new Error(response?.message);
            }

            setIsAccountBlocked(response?.data?.unpaid_invoice);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (!authTokens) {
            return;
        }

        checkUnpaidInvoices();
        checkFreeTrial();

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('redeapp_jwt');

        if (token) {
            window.location.href = `${process.env.REACT_APP_URL_PREFIX}/dashboard`;
        }
    }, [authTokens]);

    return (
        <Router>
            <Helmet>
                <title>
                    {user?.company?.name || companyWholeData?.name || 'PMP'}
                </title>
            </Helmet>
            {authTokens ? (
                <Row className="dashboard">
                    <Suspense>
                        <SideMenu/>
                    </Suspense>
                    <Col className={`app_content ${isSidebarCollapsed ? 'sidebar_collapsed' : ''}`}>
                        <Row>
                            <Suspense>
                                <UserActions/>
                            </Suspense>

                            {isAccountBlocked ? (
                                <Switch>
                                    {/* Route for '/settings' */}
                                    {userRole.roleId === 1 &&
                                        <Route path='/settings'>
                                            <Suspense fallback={<PageLoading/>}>
                                                <CompanySettings expired={isAccountBlocked} />
                                            </Suspense>
                                        </Route>

                                    }

                                    {/* Route for other pages */}
                                    <Suspense fallback={<PageLoading/>}>
                                        <BlockedAccount
                                            userRole={userRole}
                                            freeTrialAdmin={freeTrialAdmin}
                                        />
                                    </Suspense>
                                </Switch>
                            ) : !isFreeTrialExpired ? (
                                <Switch>
                                    <Redirect
                                        exec
                                        from={`${process.env.REACT_APP_URL_PREFIX}/login`}
                                        to={`${process.env.REACT_APP_URL_PREFIX}/dashboard`}
                                    />
                                    {generalSettings?.id ? userRole.roleId && routesWithGamification.map(({
                                             path,
                                             Component,
                                             type,
                                             isAvailable
                                         }) => isAvailable ? (
                                        getPermission(userRole, type) ? (
                                            <Route key={path} exact path={process.env.REACT_APP_URL_PREFIX + path}>
                                                <Suspense fallback={<PageLoading/>}>
                                                    <Component/>
                                                </Suspense>
                                            </Route>
                                        ) : (
                                            <Redirect
                                                key={path}
                                                from={process.env.REACT_APP_URL_PREFIX + path}
                                                to={`${process.env.REACT_APP_URL_PREFIX}/dashboard`}
                                            />
                                        )
                                    ) : null)
                                    : <PageLoading/>
                                }

                                {userRole.roleId && (
                                    <Route path="/*">
                                        <NotFoundUI/>
                                    </Route>
                                )}
                                </Switch>
                            ) : (
                                <Switch>
                                    {/* Route for '/settings' */}
                                    {userRole.roleId === 1 &&
                                        <Route path='/settings'>
                                            <Suspense fallback={<PageLoading/>}>
                                                <CompanySettings expired={isFreeTrialExpired} />
                                            </Suspense>
                                        </Route>

                                    }

                                    {/* Route for other pages */}
                                    <Suspense fallback={<PageLoading/>}>
                                        <FreeTrial
                                            userRole={userRole}
                                            freeTrialAdmin={freeTrialAdmin}
                                        />
                                    </Suspense>
                                </Switch>)
                            }
                        </Row>

                        <Suspense>
                            <ExportIndicator/>

                            <SelectionBar/>

                            <Footer footerLogo={eleapLogoDark}/>
                        </Suspense>
                    </Col>
                    <Suspense>
                        {(isFreeTrialExpired === false) && (isAccountBlocked === false) &&
                            <Notifications/>
                        }

                        {!!generalSettings?.pulse_point && !user?.cms_user && !isFreeTrialExpired && !isAccountBlocked &&
                            <div className="pulse_point_section">
                                <PulsePointPopUp/>
                            </div>
                        }

                        {(!!isFirstLogin || !!isTourActive) && !isMobile &&
                            <Joyride
                                callback={handleTourActions}
                                steps={getQuickTourSteps(userRole)}
                                continuous={true}
                                scrollOffset={80}
                                showProgress={true}
                                showSkipButton={true}
                                disableCloseOnEsc={true}
                                disableOverlayClose={true}
                                styles={{
                                    options: {
                                        primaryColor: '#3932E0',
                                        zIndex: 2147483002, // this because of the "intercom-frame" had the biggest z-index, so we had to exceed it
                                    }
                                }}
                            />
                        }
                    </Suspense>
                </Row>) : (
                isRedirectFromGusto ? <RedirectedGusto /> : <Login />
            )}
        </Router>
    );
};

export default React.memo(Dashboard);
